import React, { useEffect, createContext, useState, useCallback } from "react"

import Layout from "./Layout"
import Seo from "./Seo";//TODO - set title below

import 'typeface-roboto'

import { App } from "@capacitor/app";

import Pwa from './Pwa/';

const GlobalContext = createContext({
    player: null,
    setPlayer: () => { }
});

const Wrapper = ({ children, ...props }) => {
    useEffect(() => {
        App.addListener('backButton', () => {
            window.history.back();

        });
    });

    const catchExternalLink = useCallback(e => {
        if (typeof window !== undefined) {
            const currentUrl = new URL(window.location.href);

            try {
                const targetUrl = new URL(e.currentTarget.getAttribute('href'));

                if (targetUrl.host !== currentUrl.host) {
                    if (window.isIDANativeApp) {
                        const nativeData = { action: 'openExternalUrl', url: e.currentTarget.getAttribute('href') };
                        window.ReactNativeWebView.postMessage(JSON.stringify(nativeData));
                    } else {
                        window.open(targetUrl, '_blank');
                    }
                    e.preventDefault();
                }
            } catch (e) { };
        }
    });

    useEffect(() => {
        if (typeof window !== undefined) {

            document.querySelectorAll('a').forEach(el =>
                el.addEventListener('click', catchExternalLink)
            );
        }
    })

    const [player, setPlayer] = useState(null);
    return (
        <GlobalContext.Provider value={{ player, setPlayer }}>
            <Layout {...props}>
                <Pwa {...props} />
                <Seo title={`${props.entity?.title || props.entity?.identifier || 'Home'}`} />
                {children}
            </Layout>
        </GlobalContext.Provider>
    )
}


export default Wrapper;

export { GlobalContext };